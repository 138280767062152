<template>
  <UILoading v-if="loading || loadingVergelijk"></UILoading>
  <div v-else-if="statistieken" class="flex flex-col gap-8">
    <div class="card">
      <h2>Pipeline - Nog niet betaald</h2>
      <small class="block -mt-2 mb-2">Gerankschikt op datum van vertrek</small>
      <div class="grid xl:grid-cols-4 gap-4">
        <BamTarget
          v-for="timeframe in Object.keys(statistieken)"
          :key="timeframe"
          :title="capitalize(timeframe)"
        >
          <template v-slot:prefix>
            <ul>
              <li class="flex flex-row justify-between">
                <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].open_saldo_particulier + statistieken[timeframe].open_saldo_factuur) }} Open saldo</span>
                <PercentageChange
                  v-if="props.filtersVergelijken"
                  class="text-xs"
                  reverse
                  :value="statistieken[timeframe].open_saldo_particulier + statistieken[timeframe].open_saldo_factuur"
                  :previous-value="statistiekenVergelijk[timeframe].open_saldo_particulier + statistiekenVergelijk[timeframe].open_saldo_factuur"
                />
              </li>
            </ul>
          </template>
          <template v-slot:suffix>
            <ul class="pt-2 border-t border-dashboard-dark flex flex-col gap-1 text-xs">
              <li class="flex flex-col justify-between">
                <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].open_saldo_particulier) }} Zonder factuur</span>
              </li>
              <li class="flex flex-col justify-between">
                <span>&euro;&nbsp;{{ formatPrice(statistieken[timeframe].open_saldo_factuur) }} Met factuur</span>
              </li>
            </ul>
          </template>
        </BamTarget>
      </div>
      <small class="block pt-4">Open saldo is inclusief bevestigde ritten (prijs), maar ook geannuleerde ritten (annulatie kosten)</small>
    </div>

    <div class="card">
      <h2>Open saldo - Maand overzicht</h2>
      <div>
        <BarStatsMulti
          :values="[
            Object.values(grafiek?.daily || {}).map(el => (el.open_saldo_particulier || 0).toFixed(2)),
            Object.values(grafiek?.daily || {}).map(el => (el.open_saldo_factuur || 0).toFixed(2)),
            Object.values(grafiekVergelijk?.daily || {}).map(el => (el.open_saldo_particulier || 0).toFixed(2)),
            Object.values(grafiekVergelijk?.daily || {}).map(el => (el.open_saldo_factuur || 0).toFixed(2)),
          ].filter(el => el?.length)"
          :labels="[
            'Open saldo (Particulier)',
            'Open saldo (Op factuur)',
            props.filtersVergelijken && 'Open saldo (Particulier) (Vergeleken)',
            props.filtersVergelijken && 'Open saldo (Op factuur) (Vergeleken)'
          ].filter(el => el?.length)"
        ></BarStatsMulti>
      </div>
    </div>

    <div class="card">
      <h2>Open saldo - Jaar overzicht</h2>
      <div>
        <BarStatsMulti
          :categories="Object.keys(grafiek?.monthly || {}).map(el => capitalize(monthToString(el)))"
          :values="[
            Object.values(grafiek?.monthly || {}).map(el => (el.open_saldo_particulier || 0).toFixed(2)),
            Object.values(grafiek?.monthly || {}).map(el => (el.open_saldo_factuur || 0).toFixed(2)),
            Object.values(grafiekVergelijk?.monthly || {}).map(el => (el.open_saldo_particulier || 0).toFixed(2)),
            Object.values(grafiekVergelijk?.monthly || {}).map(el => (el.open_saldo_factuur || 0).toFixed(2)),
          ].filter(el => el?.length)"
          :labels="[
            'Open saldo (Particulier)',
            'Open saldo (Op factuur)',
            props.filtersVergelijken && 'Open saldo (Particulier) (Vergeleken)',
            props.filtersVergelijken && 'Open saldo (Op factuur) (Vergeleken)'
          ].filter(el => el?.length)"
        ></BarStatsMulti>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { capitalize } from '@/functions/formatText'
import { monthToString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'

import useGetApi from '@/hooks/useGetApi.js'
import useRenderKey from '@/hooks/useRenderKey.js'

import UILoading from '@/components/UI/Loading.vue'
import BamTarget from '@/components/Charts/BamTarget.vue'

const BarStatsMulti = defineAsyncComponent(() => import('@/components/Charts/BarStatsMulti.vue'))
const PercentageChange = defineAsyncComponent(() => import('@/components/UI/Labels/PercentageChange.vue'))

const props = defineProps({
  filters: Object,
  filtersVergelijken: Object,
  renderKey: [String, Number],
})

useRenderKey(props.renderKey)

const { data, loading } = useGetApi('STATS_PIPELINE', props.filters, { watch: true })
const { data: dataVergelijk, loading: loadingVergelijk } = useGetApi('STATS_PIPELINE', props.filtersVergelijken, { watch: true, skipMounted: !props.filtersVergelijken })

const statistieken = computed(() => data.value?.statistieken || {})
const grafiek = computed(() => data.value?.grafiek || { daily: {}, monthly: {}, yearly: {} })

const statistiekenVergelijk = computed(() => dataVergelijk.value?.statistieken || {})
const grafiekVergelijk = computed(() => dataVergelijk.value?.grafiek || { daily: {}, monthly: {}, yearly: {} })
</script>
